import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { OnlyMobileAllowed, QRVerificationPage, SendSMS } from '@containers';
import { LoadingSpinner } from '@FLOW_V2_FLOW/components';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';
import { ALLOW_MOBILE_EMULATORS, NON_MOBILE_DEVICE_FALLBACK } from '@spotMobileConfig';
import { NON_MOBILE_DEVICE_SCREEN_TYPES } from '@lib/constants/nonMobileDeviceScreenTypes';

export const DEVICE_MOTION_DETECTION_STATE = {
  IDDLE: 'IDDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  DENIED: 'DENIED',
  FAILED: 'FAILED'
};

const useDeviceMotionDetection = () => {
  const [deviceMotionStatus, setDeviceMotionStatus] = useState(DEVICE_MOTION_DETECTION_STATE.IDDLE);

  const onDeviceMotionEvent = useCallback(
    (event) => {
      const { x, y, z } = event.accelerationIncludingGravity;
      if (
        deviceMotionStatus !== DEVICE_MOTION_DETECTION_STATE.IDDLE &&
        deviceMotionStatus !== DEVICE_MOTION_DETECTION_STATE.LOADING
      ) {
        window.removeEventListener('devicemotion', onDeviceMotionEvent);
      } else if (!x || !y || !z) {
        setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.FAILED);
      } else {
        setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.SUCCESS);
      }
    },
    [deviceMotionStatus]
  );

  useEffect(() => {
    window.addEventListener('devicemotion', onDeviceMotionEvent);

    return () => window.removeEventListener('devicemotion', onDeviceMotionEvent);
  }, [onDeviceMotionEvent]);

  const checkDeviceMotion = () => {
    if (deviceMotionStatus !== DEVICE_MOTION_DETECTION_STATE.IDDLE) {
      return;
    }

    const MAX_DELAY_DETECTING_MOTION_IN_MS = 10000;
    if (
      typeof DeviceOrientationEvent !== 'undefined' &&
      typeof DeviceOrientationEvent.requestPermission === 'function'
    ) {
      setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.LOADING);

      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === 'granted') {
            setTimeout(function failIfNoMotionDetectedAfterTimeout() {
              console.error('Timeout device motion');
              setDeviceMotionStatus((currentStatus) => {
                if (
                  currentStatus === DEVICE_MOTION_DETECTION_STATE.IDDLE ||
                  currentStatus === DEVICE_MOTION_DETECTION_STATE.LOADING
                ) {
                  return DEVICE_MOTION_DETECTION_STATE.FAILED;
                }
                return currentStatus;
              });
            }, MAX_DELAY_DETECTING_MOTION_IN_MS);
          } else if (permissionState === 'denied') {
            setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.DENIED);
          } else {
            console.warn({ permissionState });
            throw new Error('DeviceOrientationEvent access failed');
          }
        })
        .catch((err) => {
          console.error(err);
          setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.FAILED);
        });
    }
  };

  return { deviceMotionStatus, checkDeviceMotion };
};

const DeviceMotionDetectionContext = React.createContext({});

export const withDeviceMotionDetectionProvider = (OriginalComponent) => {
  function WithDeviceMotionProvider(props) {
    return (
      <DeviceMotionDetectionProvider>
        <OriginalComponent {...props} />
      </DeviceMotionDetectionProvider>
    );
  }

  return WithDeviceMotionProvider;
};

export const DeviceMotionDetectionProvider = ({ children }) => {
  const { deviceMotionStatus, checkDeviceMotion } = useDeviceMotionDetection();

  const value = useMemo(() => {
    return { deviceMotionStatus, checkDeviceMotion };
  }, [deviceMotionStatus, checkDeviceMotion]);

  return (
    <DeviceMotionDetectionContext.Provider value={value}>
      {children}
    </DeviceMotionDetectionContext.Provider>
  );
};

DeviceMotionDetectionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useDeviceMotionDetectionContext = () => {
  const { deviceMotionStatus, checkDeviceMotion } = useContext(DeviceMotionDetectionContext);

  if (deviceMotionStatus === undefined) {
    throw new Error(
      'deviceMotionStatus is undefined. Make sure useDeviceMotionDetectionContext is used within a DeviceMotionDetectionContext'
    );
  }

  return { deviceMotionStatus, checkDeviceMotion };
};

export const withDeviceMotionDetection = (OriginalComponent) => {
  function NoMotionDetectionWrapper(props) {
    return <OriginalComponent {...props} />;
  }

  function MotionDetectionWrapper(props) {
    const { deviceMotionStatus, checkDeviceMotion } = useDeviceMotionDetectionContext();

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.IDDLE) {
      return (
        <Message
          buttons={[
            {
              label: localizedString('proceed'),
              onClick: () => {
                checkDeviceMotion();
              }
            }
          ]}
          title="Motion and Rotation Access"
        >
          <p>To continue, please grant access to the motion and rotation sensors on your device.</p>
          <p>
            This allows the website to sense the movement of your device, which helps to confirm
            device compatibility.
          </p>
          <p>
            This information will not be stored or shared with any third parties. You can revoke
            access after completing the verification process.
          </p>
        </Message>
      );
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.LOADING) {
      return <LoadingSpinner heading={localizedString('loading')} />;
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.FAILED) {
      if (NON_MOBILE_DEVICE_SCREEN_TYPES.SMS === NON_MOBILE_DEVICE_FALLBACK) {
        return <SendSMS />;
      }

      if (NON_MOBILE_DEVICE_SCREEN_TYPES.QR === NON_MOBILE_DEVICE_FALLBACK) {
        return <QRVerificationPage />;
      }

      return <OnlyMobileAllowed />;
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.SUCCESS) {
      return (
        <OriginalComponent
          {...props}
          deviceMotionStatus={deviceMotionStatus}
          checkDeviceMotion={checkDeviceMotion}
        />
      );
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.DENIED) {
      return (
        <Message
          issue
          buttons={[
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                window.location.reload();
              }
            }
          ]}
          title="Enable Motion and Rotation Access"
        >
          It seems "Motion and Rotation Access" has been denied, please make sure this is enabled
          and try again. You might need to close and open the browser if you denied it from the
          browser.
        </Message>
      );
    }
  }

  if (ALLOW_MOBILE_EMULATORS) {
    return NoMotionDetectionWrapper;
  }

  return MotionDetectionWrapper;
};
